<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>

        <v-row justify="start">
        <v-col
                cols="auto"
                v-for="title in titles"
                v-bind:key="title.link"
                class="viewCard"
        >
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <router-link :to="title.link">
                        <v-card
                                :elevation="10"
                                class="blue darken-1 text-center"
                                height="100"
                                width="200"
                        >
                            <v-list-item three-line>
                                <v-list-item-content class="white--text"> 
                                    <v-row align="center" justify="center">
                                        <span>{{title.name}}</span>
                                    </v-row>
                                    <v-divider></v-divider>
                                    <v-row align="center" justify="center">
                                        <span>100</span>
                                    </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </router-link>
                </template>
            </v-tooltip>

        </v-col>
    </v-row>
        <!--<v-row class="mt-10">
            <v-card>
                <v-col cols="12" sm="3">
                    <v-card @click="$router.push('/hotel')" color="#006400" elevation="5">
                        <v-row>
                            <v-col cols="12" sm="5">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-avatar>
                                            <v-icon color="white" large>people</v-icon>
                                        </v-list-avatar>

                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-list-item three-line>
                                    <div>
                                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                            Customer
                                        </v-list-item-subtitle>

                                    </div>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="3">
                    <v-card @click="$router.push('/hotelEmployee')" color="#FFA500" elevation="5">
                        <v-row>
                            <v-col cols="12" sm="5">
                                <v-list-item three-line>
                                    <v-list-item-avatar>
                                        <v-icon color="white" large>directions_run</v-icon>
                                    </v-list-item-avatar>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-list-item three-line>
                                    <v-list-item-content class="text-right">
                                        <div>
                                            <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                Employee
                                            </v-list-item-subtitle>

                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-card @click="$router.push('/availableRoom')" color="#077dfa" elevation="5">
                        <v-row>
                            <v-col cols="12" sm="5">
                                <v-list-item three-line>
                                    <v-list-item-avatar>
                                        <v-icon color="white" large>room_service</v-icon>
                                    </v-list-item-avatar>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-list-item three-line>
                                    <v-list-item-content class="text-right">
                                        <div>
                                            <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                Room
                                            </v-list-item-subtitle>

                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

            </v-card>


        </v-row>-->


    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Dashboard",
        watch: {},
        components: {},
        computed: {
            titles() {
                return [
                    {
                        name: this.$t('hotel_room'),
                        link: "/hotel/room/setting"
                    },
                    {
                        name: this.$t('package'),
                        link: "/hotel/package/setting"
                    },
                    {
                        name: this.$t('service'),
                        link: "/hotel/service/setting"
                    },
                    {
                        name: this.$t('room_category/type'),
                        link: "/hotel/category/setting"
                    },
                    ]
            },
            items() {
                return [
                    {
                        text: this.$t('settings'),
                        disabled: true,
                        to: "/Hotel",
                        exact: true
                    }
                    ]
            }
            },
        data() {
            return {
                branchInformation: []

            }
        },
        mounted() {
            this.formData()
        },
        methods: {
            async formData() {
                const dashboardData = await axios.get(
                    "Branch/GetBranchInformationByBranchID"
                );
                this.branchInformation = dashboardData.data;
            },
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>